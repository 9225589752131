import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/facilities';

class FacilityService {

    search(query) {
        return axios.get(API_URL + '/search/'+query+'.json', { headers: authHeader() });
    }
}

export default new FacilityService();