import React, { Component } from 'react';
import TrackingActionBtn from '../../Account/TrackingActionBtn'
import TrackingFilterBlock from "../../Account/TrackingFilterBlock";
import moment from 'moment';

import TrackingService from "../../services/tracking.service";

export default class TrackingList extends Component {
    state = {
        isLoading: true,
        trackings: []
    };

    async componentDidMount() {
        const response = await TrackingService.getAll();
        this.setState({ trackings: response.data, isLoading: false });
    }

    async remove(id) {
        await TrackingService.remove(id);
        let updatedTrackings = this.state.trackings.filter(i => i.id !== id);
        this.setState({trackings: updatedTrackings});
    }

    async changeStatus(id, status) {
        console.log("change status to "+status);
        const response = await TrackingService.changeStatus(id, status);

        const tracking = response.data;
        const updatedTrackings = this.state.trackings.map(i => {
            return (i.id === id) ? tracking : i
        });
        this.setState({trackings: updatedTrackings});
    }

    render() {
        const {trackings, isLoading} = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        return (

            <div id="list">
                <div className="container">

                        <div className="row">
                            <div className="col-xs-12 ">
                                <div className="heading-wrapper button-center">
                                    <h1>All monitored campgrounds</h1>
                                </div>
                            </div>
                        </div>

                    {trackings.length !== 0 &&
                        <div className="row">
                            <div className="col-xs-12 list-wrapper">

                                <div className="list-title flx">
                                    <div className="col1">Campground</div>
                                    <div className="col2">Package</div>
                                    <div className="col3">Campsite</div>
                                    <div className="col4">Action</div>
                                    <div className="col4">Action</div>
                                </div>

                                {trackings.map(tracking =>

                                    <div key={tracking.id} className="list-description flx">

                                        <div className="col1">
                                            <span className="list-title-mobile">Campground:</span>
                                            <div className="list-content flx flx-wrap">
                                                <div className="campground flx">
                                                    <div className="camp-image">
                                                        <img width="100"
                                                             alt=""
                                                             src={ tracking.facility.images.length ? tracking.facility.images[tracking.facility.images.length-1].url : "/images/camp.svg"}
                                                             onError={(e)=>{e.target.onerror = null; e.target.src="/images/camp.svg"}}
                                                        />
                                                    </div>
                                                    <div className="camp-title">
                                                        <div>
                                                            <strong>
                                                                <a href={"https://www.recreation.gov/camping/campgrounds/"+tracking.facility.id+"/availability"}>
                                                                    {tracking.facility.name}
                                                                </a>
                                                            </strong>
                                                        </div>
                                                        <div>
                                                            <strong>Check In:</strong>
                                                            <span> {moment(tracking.dateCheckInStart).format("MM-DD-YYYY (dd)")}</span>
                                                            {tracking.dateCheckInStart !== tracking.dateCheckInEnd &&
                                                                <span> - {moment(tracking.dateCheckInEnd).format("MM-DD-YYYY (dd)")}</span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <strong>Nights:</strong>
                                                            <span> {tracking.lengthOfStay}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <TrackingFilterBlock
                                                    campsiteFilter={tracking.campsiteFilter}
                                                    campsites={tracking.campsites}
                                                />


                                            </div>
                                        </div>

                                        <div className="col2">
                                            <span className="list-title-mobile">Package:</span>
                                            <div className="list-content">
                                                <div>
                                                    <strong>{tracking.type.name} </strong>
                                                    <small>({tracking.type.description})</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col3">
                                            <span className="list-title-mobile">Campsite:</span>
                                            <div className="list-content">

                                                {(function() {
                                                    switch(tracking.status.id) {
                                                        case 101:
                                                            return (
                                                                <div>
                                                                    <strong>#{tracking.campsite.name}</strong>
                                                                    {tracking.reservationId &&
                                                                        <span className="guarantee">
                                                                            Guaranteed until {new Intl.DateTimeFormat(
                                                                                'en-US',
                                                                            {
                                                                                year: '2-digit', month: '2-digit', day: '2-digit',
                                                                                hour: 'numeric', minute: 'numeric'
                                                                            }).format(new Date(tracking.guaranteeUntil))}

                                                                        </span>
                                                                    }

                                                                    <div><strong>Type: </strong> {tracking.campsite.type}</div>
                                                                    <div><strong>Loop: </strong> {tracking.campsite.loop}</div>

                                                                    <a className="bookSite btn btn-yellow"
                                                                        href={"https://www.recreation.gov/camping/campgrounds/"
                                                                            +tracking.facility.id+"/campsites?arrivaldate="
                                                                            +moment(tracking.dateCheckIn).format("MM-DD-YYYY")
                                                                            +"&departuredate="
                                                                            +moment(tracking.dateCheckIn).add(tracking.lengthOfStay, 'days').format("MM-DD-YYYY")
                                                                        }
                                                                       target="_blank"
                                                                    >
                                                                        Book <span className="icon-right-arrow list-icon"/>
                                                                    </a>

                                                                </div>
                                                            );
                                                        case 102:
                                                            return <span>Paused</span>;
                                                        case 100:
                                                            return <span>In progress</span>;
                                                        case 104:
                                                            return <span>Expired</span>;
                                                        default:
                                                            return null;
                                                    }
                                                })()}
                                            </div>
                                        </div>

                                        <div className="col4">
                                            <span className="list-title-mobile">Actions:</span>
                                            <div className="list-content">
                                                {tracking.status.id === 100 && <TrackingActionBtn
                                                    title="Pause"
                                                    iconClassName = "icon-pause list-icon"
                                                    onClick={() => this.changeStatus(tracking.id, "pause")}
                                                />}


                                                {tracking.status.id === 101 && <TrackingActionBtn
                                                    title="Restart"
                                                    iconClassName = "icon-resume list-icon"
                                                    onClick={() => this.changeStatus(tracking.id, "restart")}
                                                />}

                                                {tracking.status.id === 102 && <TrackingActionBtn
                                                    title="Resume"
                                                    iconClassName = "icon-resume list-icon"
                                                    onClick={() => this.changeStatus(tracking.id, "restart")}
                                                />}

                                                <TrackingActionBtn
                                                    title="Delete"
                                                    iconClassName = "icon-delete list-icon"
                                                    onClick={() => this.remove(tracking.id)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-xs-12">
                            <div className="text-center">
                                <a className="btn btn-green" href="/account/tracking/add">
                                    <span className="inner-btn">Add new tracking</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}