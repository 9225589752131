import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api/auth";

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + "/login", {
                username,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(user) {
        return axios.post(API_URL + "/register", user);
    }

    confirm(token, password) {
        return axios
            .post(API_URL + "/confirm", {
                token,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    restorePassword(email) {
        return axios.post(API_URL + "/restorePassword", {email: email});
    }

    updateProfile(user) {
        return axios
            .patch(API_URL + "/profile", user, { headers: authHeader() })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    updatePassword(currentPassword, password) {
        return axios.patch(API_URL + "/passwordupdate",
            {currentPassword: currentPassword, password: password},
            { headers: authHeader() }
        );
    }
}

export default new AuthService();