import React, { Component } from 'react';
import {Formik, Form, Field} from "formik";

import AuthService from "../services/auth.service";

import * as Yup from "yup";
import SimpleNavigation from "../SimpleNavigation";

export default class RestorePassword extends Component {

    state = {
        errorMessage: null,
        loading: false
    };

    async componentDidMount() {};

    submitForm = async (values, { resetForm }) => {
        this.setState({errorMessage:null});

        this.setState({ loading: true });

        try {
            const response = await AuthService.restorePassword(values.email);
            this.setState({successMessage : response.data.message});
            resetForm();
        } catch(err) {
            this.setState({errorMessage : err.response.data.message});
        }

        this.setState({ loading: false });
    };

    render() {

        // validation
        const formValues = {
            email: "",
        };

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .required("Email is required")
                .email("Please enter a valid email"),
        });

        return (
            <div>
                <SimpleNavigation/>

                <div id="login">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 center-col">
                                <div className="heading-wrapper">
                                    <h1 className="button-center">Forgot Your Password?</h1>
                                </div>

                                <Formik
                                    initialValues={formValues}
                                    validationSchema={validationSchema}
                                    onSubmit={this.submitForm}
                                >
                                    {(formik) => {
                                        return (
                                            <Form noValidate>

                                                <div className="notification-box">Please enter your email address and we
                                                    will send you an email about how to reset your password.
                                                </div>

                                                {this.state.errorMessage &&
                                                <div>
                                                    <div className="alert alert-danger">{ this.state.errorMessage }</div>
                                                </div>
                                                }

                                                {this.state.successMessage &&
                                                <div>
                                                    <div className="alert alert-success">{ this.state.successMessage }</div>
                                                </div>
                                                }

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.email && formik.touched.email ? "wrong-email":"")}>
                                                    <Field type="email" name="email" placeholder="" className="form-control" />
                                                    <label htmlFor="email">
                                                        {formik.errors.email && formik.touched.email
                                                            ? formik.errors.email
                                                            : "Email"
                                                        }
                                                    </label>
                                                    <span className="icon-user icon-form"></span>
                                                </div>

                                                <div className="button-center">
                                                    <button type="submit" disabled={this.state.loading} className="btn btn-green"><span className="inner-btn">Reset Password</span>
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                                <div className="button-center">
                                    <a className="forgot-pass" href="/login">Back to login page</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


