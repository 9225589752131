import React, { Component } from 'react';
import { Route, BrowserRouter as Router} from 'react-router-dom';

import Profile from "../components/account/profile.component";
import TrackingList from "../components/account/trackingList.component";
import TrackingAdd from "../components/account/trackingAdd.component";

import Navigation from "./Navigation";
import UserNotes from "./UserNotes";


class Index extends Component {


    render() {
        if ( ! localStorage.getItem('user')) {
            window.location.href = '/login';
            return;
        }

        return (
            <Router>
                <Navigation/>
                <UserNotes/>
                <Route exact path="/account/tracking" component={TrackingList} />
                <Route path="/account/tracking/add" component={TrackingAdd} />
                <Route path="/account/profile" component={Profile} />
            </Router>
        );
    }
}

export default Index;
