import React, { Component } from 'react';
import {Formik, Form, Field} from "formik";

import AuthService from "../../services/auth.service";


import * as Yup from "yup";

export default class ProfilePersonalInfo extends Component {


    state = {
        errorMessage: null,
        successMessage: null,
        loading: false,
        user: JSON.parse(localStorage.getItem('user'))
    };

    async componentDidMount() { };

    submitForm = async (values) => {
        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            await AuthService.updateProfile({
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber
            });
            this.setState({successMessage : "Success! Your account has been updated!"});
        } catch(err) {
            this.setState({errorMessage : err.response.data.message});
        }

        this.setState({ loading: false });
    };


    render() {
        // validation
        const formValues = {
            firstName: this.state.user.firstName,
            lastName: this.state.user.lastName,
            phoneNumber: this.state.user.phoneNumber,
        };

        const validationSchema = Yup.object().shape({
            firstName: Yup.string()
                .required("First Name is required"),
            lastName: Yup.string()
                .required("Last Name is required"),
            phoneNumber: Yup.number()
                .typeError("Invalid Phone Number")
                .positive("Invalid Phone Number")
                .integer("Invalid Phone Number")
        });

        return (
            <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={this.submitForm}
            >
                {(formik) => {
                    return (
                        <Form noValidate id="profile-form">

                            {this.state.errorMessage &&
                            <div>
                                <div className="alert alert-danger">{ this.state.errorMessage }</div>
                            </div>
                            }

                            {this.state.successMessage &&
                            <div>
                                <div className="alert alert-success">{ this.state.successMessage }</div>
                            </div>
                            }

                            <div className="flx flx-yc disabled-form-wrap">
                                <div id='personal-email'>{ this.state.user.email }</div>
                                <span className="icon-black-envelope icon-form"></span>
                            </div>

                            <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                            +(formik.errors.firstName && formik.touched.firstName ? "wrong-email":"")}>
                                <Field type="text" name="firstName" placeholder="" className="form-control" />
                                <label htmlFor="firstName">
                                    {formik.errors.firstName && formik.touched.firstName
                                        ? formik.errors.firstName
                                        : "First Name"
                                    }
                                </label>
                                <span className="icon-user icon-form"></span>
                            </div>


                            <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                            +(formik.errors.lastName && formik.touched.lastName ? "wrong-email":"")}>
                                <Field type="text" name="lastName" placeholder="" className="form-control" />
                                <label htmlFor="lastName">
                                    {formik.errors.lastName && formik.touched.lastName
                                        ? formik.errors.lastName
                                        : "Last Name"
                                    }
                                </label>
                                <span className="icon-user icon-form"></span>
                            </div>


                            <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                            +(formik.errors.phoneNumber && formik.touched.phoneNumber ? "wrong-email":"")}>
                                <Field type="text" name="phoneNumber" placeholder="" className="form-control" />
                                <label htmlFor="phoneNumber">
                                    {formik.errors.phoneNumber && formik.touched.phoneNumber
                                        ? formik.errors.phoneNumber
                                        : "Phone Number For Notifications"
                                    }
                                </label>
                                <span className="icon-phone icon-form"></span>
                            </div>

                            <div className="button-center mt-20">
                                <button type="submit" disabled={this.state.loading} className="btn btn-green">
                                    <span className="inner-btn">Save</span>
                                </button>
                            </div>

                        </Form>
                    );
                }}
            </Formik>
        );
    }
}