import React, { Component } from "react";
import Select, { components } from "react-select";

const Option = props => (
    <div>
        <components.Option {...props}>
            <div className="checkbox">
                <input type="checkbox"
                       checked={props.isSelected}
                       onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </div>
        </components.Option>
    </div>
);

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const MenuListStyle = {
    padding: '8px 12px',

};

const MenuList = (props) => {
    const valueLength = props.getValue().length;
    let optionsLength = props.options.length;
    return (
        <components.MenuList {...props}>
            <div style={MenuListStyle}>
                {optionsLength > 0 &&
                    <div className="checkbox">
                        <input type="checkbox"
                               style={{"zIndex": 1}}
                                checked={valueLength === optionsLength}
                                onChange={(event) => {
                                    props.setValue(event.target.checked ? props.options : []);
                                }}
                        />{" "}
                        <label><strong>Select All</strong></label>
                    </div>
                }
            </div>
            {props.children}
        </components.MenuList>
    );

};


const ValueContainer = ({ children, getValue, ...props }) => {
    let valueLength = getValue().length;
    let optionsLength = props.selectProps.options.length;
    /*var showOptions = React.Children.count(children) < 3;*/
    // Check if values are equal to options and display `All` instead of number.
    valueLength = optionsLength === valueLength ? "All "+optionsLength : valueLength;

    let showChildren = valueLength < 2 && optionsLength > 0;

    return (
        <components.ValueContainer {...props}>
            {(! showChildren) &&
                (optionsLength === 0 ? "No Items" : `${valueLength} selected`)
            }

            {showChildren && React.Children.map(children, child => {
                    return ((child.type === components.Input)) ? null : child;
                })
            }
            {React.cloneElement(children[1])}
        </components.ValueContainer>
    );
};

const customStyles = {
    option: (provided, state) => {
        const backgroundColor = state.isFocused ? '#DEEBFF':'#ffffff';
        const color = '#575756';
        return { ...provided, backgroundColor, color };
    }
};


class SelectChkbx extends Component {

    static defaultProps = {
        options: []
    };

    render() {
        const { ...otherProps } = this.props;

        return (
            <Select
                styles={customStyles}
                closeMenuOnSelect={false}
                isMulti
                isSearchable
                components={{ Option, MultiValue, ValueContainer, MenuList }}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                {...otherProps}
            />
        );
    }
}

export default SelectChkbx;
