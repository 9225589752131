import React, { Component } from 'react';
import {Formik, Form, Field} from "formik";

import AuthService from "../services/auth.service";

import * as Yup from "yup";
import SimpleNavigation from "../SimpleNavigation";

export default class Login extends Component {

    state = {
        error: false,
        loading: false,
    };

    async componentDidMount() {

    };

    submitForm = async (values) => {
        this.setState({error:false});

        this.setState({ loading: true });

        try {
            await AuthService.login(values.email, values.password);
            this.props.history.push("/account/tracking");
        } catch(err) {
            this.setState({error : true})
        }

        this.setState({ loading: false });
    };

    render() {

        // validation
        const formValues = {
            email: "",
            password: "",
        };

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .required("Email is required")
                .email("Please enter a valid email"),
            password: Yup.string()
                .required("Password is required")
        });

        return (
            <div>
                <SimpleNavigation/>

                <div id="login">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 center-col">
                                <div className="heading-wrapper">
                                    <h1 className="button-center">Member Sign In</h1>
                                </div>

                                <Formik
                                    initialValues={formValues}
                                    validationSchema={validationSchema}
                                    onSubmit={this.submitForm}
                                >
                                    {(formik) => {
                                        return (
                                            <Form noValidate>

                                                {this.state.error &&
                                                <div>
                                                    <div className="alert alert-danger">Invalid username or password.
                                                    </div>
                                                </div>
                                                }

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                    +(formik.errors.email && formik.touched.email ? "wrong-email":"")}>
                                                    <Field type="email" name="email" placeholder="" className="form-control" />
                                                    <label htmlFor="email">
                                                        {formik.errors.email && formik.touched.email
                                                            ? formik.errors.email
                                                            : "Email"
                                                        }
                                                    </label>
                                                    <span className="icon-user icon-form"></span>
                                                </div>

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.password && formik.touched.password ? "wrong-email":"")}>
                                                    <Field type="password" name="password" placeholder="" className="form-control" />
                                                    <label htmlFor="password">
                                                        {formik.errors.password && formik.touched.password
                                                            ? formik.errors.password
                                                            : "Password"
                                                        }
                                                    </label>
                                                    <span className="icon-lock icon-form"></span>
                                                </div>

                                                <div className="button-center">
                                                    <button type="submit" disabled={this.state.loading} className="btn btn-green"><span className="inner-btn">Sign in</span>
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                                <div className="button-center">
                                    <a className="forgot-pass" href="/restorePassword">Forgot your password?</a>
                                </div>
                                <div className="button-center">
                                    <a className="forgot-pass" href="/register">New user? Sign up to create your
                                        account.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


