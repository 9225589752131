import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/trackings';

class UserService {

    getAll() {
        return axios.get(API_URL, { headers: authHeader() });
    }

    create(tracking) {
        return axios.post(API_URL, tracking, { headers: authHeader() });
    }

    changeStatus(id, status) {
        return axios.patch(
            API_URL + `/${id}`,
            {status:status},
            { headers: authHeader() }
        );
    }

    remove(id) {
        return axios.delete(
            API_URL + `/${id}`,
            { headers: authHeader() }
        );
    }
}

export default new UserService();