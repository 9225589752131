import React, { Component } from 'react';

import ProfilePersonalInfo from "./profile.personalInfo.component";
import ProfilePassword from "./profile.password.component";

export default class Profile extends Component {

    render() {

        return (
            <div>
                <div id="profile">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 center-col heading-wrapper">
                                <h1 className="button-center">Personal Profile</h1>
                            </div>

                            <div className="col-xs-12 col-sm-6">
                                <h5>Personal info</h5>
                                <ProfilePersonalInfo/>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <h5>Password</h5>
                                <ProfilePassword/>
                            </div>
                            <div className="col-xs-12 col-sm-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}