import React, { Component } from 'react';
import {Link} from "react-router-dom";

export default class SimpleNavigation extends Component {

    render() {
        return (
            <nav className="navbar navbar-default global-box-shadow" id="nav">
                <div className="container">
                    <div className="navbar-header">
                        <div className="logo">
                            <Link to="/" className="navbar-brand">CAMPING WHIZZ</Link>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}