import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/campsites';

class CampsiteService {
    getByFacility(facilityId) {
        return axios.get(API_URL + '?facilityId='+facilityId, { headers: authHeader() });
    }
}

export default new CampsiteService();