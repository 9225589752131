import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import axios from 'axios';

import App from './App';
import LoginComponent from "./components/login.component";
import RegisterComponent from "./components/register.component";
import ConfirmComponent from "./components/confirm.component";
import RestorePasswordComponent from "./components/restorePassword";
import Account from "./Account/index";
import Notfound from './NotFound';

import TagManager from 'react-gtm-module'

import './css/master.css';
import './css/style.css';
import './css/icons.css';
import './css/inprod.css';



/*axios.interceptors.request.use(request => {
    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});*/

axios.interceptors.response.use(response => {
    // Edit response config
    return response;
}, error => {
    if (error.response.status === 401 && error.response.data.path !== "/api/auth/login") {
        localStorage.removeItem("user");
        window.location.href = '/login'
    }
    return Promise.reject(error);
});

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({gtmId: 'GTM-THXS72C'});
}


const routing = (
    <div>
        <Router>
            <Switch>
                <Route exact path="/" component={App} />
                <Route path="/login" component={LoginComponent} />
                <Route path="/register" component={RegisterComponent} />
                <Route path="/confirm/:token" component={ConfirmComponent} />
                <Route path="/restorePassword" component={RestorePasswordComponent} />
                <Route path="/account" component={Account} />
                <Route component={Notfound} />
            </Switch>
        </Router>

        <section id="footer">
            <div className="footer-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <p>2018 Camping Whizz. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);
ReactDOM.render(routing, document.getElementById('main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
