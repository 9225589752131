import React, { Component } from 'react';
import {Formik, Form, Field} from "formik";
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

import AuthService from "../services/auth.service";

import * as Yup from "yup";
import SimpleNavigation from "../SimpleNavigation";

export default class Register extends Component {

    captchaSiteKey = "6LcT6ccaAAAAAE_Z6NbzF-cjcWz-oOaIGwhFn8kw";
    captchaAction = "register";



    state = {
        errorMessage: null,
        successMessage: null,
        loading: false,
        recaptchaToken: null
    };

    async componentDidMount() {
       loadReCaptcha(this.captchaSiteKey);
    };

    submitForm = async (values, { resetForm }) => {
        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            const response = await AuthService.register({
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                captcha: this.state.recaptchaToken
            });
            this.setState({successMessage : response.data.message});
            resetForm();
        } catch(err) {
            this.setState({errorMessage : err.response.data.message});
        }
        this.recaptcha.execute(); // refresh captcha
        this.setState({ loading: false });
    };

    verifyCallback = (recaptchaToken) => {
        this.setState({recaptchaToken});
    }

    render() {

        // validation
        const formValues = {
            email: "",
            firstName: "",
            lastName: "",
        };

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .required("Email is required")
                .email("Please enter a valid email"),
            firstName: Yup.string()
                .required("First Name is required"),
            lastName: Yup.string()
                .required("Last Name is required")
        });

        return (
            <div>
                <SimpleNavigation/>

                <div id="register">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 center-col">
                                <div className="heading-wrapper">
                                    <h1 className="button-center">Member registration</h1>
                                </div>

                                <Formik
                                    initialValues={formValues}
                                    validationSchema={validationSchema}
                                    onSubmit={this.submitForm}
                                >
                                    {(formik) => {
                                        return (
                                            <Form noValidate>

                                                {this.state.errorMessage &&
                                                <div>
                                                    <div className="alert alert-danger">{ this.state.errorMessage }</div>
                                                </div>
                                                }

                                                {this.state.successMessage &&
                                                <div>
                                                    <div className="alert alert-success">{ this.state.successMessage }</div>
                                                </div>
                                                }

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.email && formik.touched.email ? "wrong-email":"")}>
                                                    <Field type="email" name="email" placeholder="" className="form-control" />
                                                    <label htmlFor="email">
                                                        {formik.errors.email && formik.touched.email
                                                            ? formik.errors.email
                                                            : "Email"
                                                        }
                                                    </label>
                                                    <span className="icon-black-envelope icon-form"></span>
                                                </div>

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.firstName && formik.touched.firstName ? "wrong-email":"")}>
                                                    <Field type="text" name="firstName" placeholder="" className="form-control" />
                                                    <label htmlFor="firstName">
                                                        {formik.errors.firstName && formik.touched.firstName
                                                            ? formik.errors.firstName
                                                            : "First Name"
                                                        }
                                                    </label>
                                                    <span className="icon-user icon-form"></span>
                                                </div>

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.lastName && formik.touched.lastName ? "wrong-email":"")}>
                                                    <Field type="text" name="lastName" placeholder="" className="form-control" />
                                                    <label htmlFor="lastName">
                                                        {formik.errors.lastName && formik.touched.lastName
                                                            ? formik.errors.lastName
                                                            : "Last Name"
                                                        }
                                                    </label>
                                                    <span className="icon-user icon-form"></span>
                                                </div>

                                                <ReCaptcha
                                                    ref={ref => this.recaptcha = ref}
                                                    sitekey={this.captchaSiteKey}
                                                    action={this.captchaAction}
                                                    verifyCallback={this.verifyCallback}
                                                />

                                                <div className="button-center">
                                                    <button type="submit" disabled={this.state.loading} className="btn btn-green">
                                                        <span className="inner-btn">Sign Up</span>
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                                <div className="button-center mt-20">
                                    <a className="forgot-pass" href="/login">Already have an account? Sign in</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}