import React, { Component } from 'react';

class Tooltip extends Component {
    state = {
        isVisible: false
    };

    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClickOutside);
    };

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isVisible) {
                this.setState({isVisible: false});
            }
        }
    };

    onTooltipClick = () => {
        this.setState(oldState => {
            return {isVisible: !oldState.isVisible}
        });
    };

    render() {
        return (
            <div className="info-icon-wrap" onClick={this.onTooltipClick}>
                <span  ref={this.setWrapperRef} className="info-icon-wrap-filter">...</span>
                {this.state.isVisible &&
                    <div
                        className="tooltip-bottom tooltip-wrap global-box-shadow">
                        <div className="tooltip__arrow"/>
                        <p>{this.props.text}</p>
                    </div>
                }
            </div>
        );
    }
}
export default Tooltip;
