import React, { Component } from 'react';
import Tooltip from "../Tooltip";


class TrackingFilterBlock extends Component {

    render() {

        const otherFeatures = {
            attrPetsAllowed: "Pets allowed",
            accessibilityNeeds: "Accessibility needs",
            attrWaterHookup: "Water hookup",
            attrSewerHookup: "Sewer hookup",
            attrPullThroughDriveway: "Pull-through driveway",
            attrWaterfront: "Waterfront"
        };

        const campsiteFilter = JSON.parse(this.props.campsiteFilter);
        const campsites = JSON.parse(this.props.campsites);

        const typesStr = campsiteFilter.types ? campsiteFilter.types.join(", ") : null;
        const loopsStr = campsiteFilter.loops ? campsiteFilter.loops.join(", ") : null;

        const existOtherAttr = (campsiteFilter.attributes) ? Object.keys(campsiteFilter.attributes).filter(k => {return otherFeatures[k] ? otherFeatures[k] : null}) : [];
        const existOtherAttrStr = existOtherAttr.map(v => otherFeatures[v]).join(", ");

        const existCampsiteFilter = Object.keys(campsiteFilter).length > 0;

        const campsitesStr = (campsites.length > 0) ? campsites.map(obj => obj.name).join(", ") : null;


        return (
            <div>
                <div className="filter-details">
                    {existCampsiteFilter &&
                        <div>
                            {typesStr &&
                                <div className="filter-line">
                                    <strong>Campsite Types: </strong>
                                    {typesStr.substring(0, 20)}
                                    {typesStr.length > 20 &&
                                        <Tooltip text={typesStr}/>
                                    }
                                </div>
                            }

                            {loopsStr &&
                                <div className="filter-line">
                                    <strong>Campsite Loops: </strong>
                                    {loopsStr.substring(0, 20)}
                                    {loopsStr.length > 20 &&
                                        <Tooltip text={loopsStr}/>
                                    }
                                </div>
                            }

                            {campsiteFilter.attributes && campsiteFilter.attributes.attrOccupants &&
                                <div className="filter-line">
                                    <strong>Number of Occupants: </strong>
                                    {campsiteFilter.attributes.attrOccupants}
                                </div>
                            }

                            {existOtherAttrStr &&
                                <div className="filter-line">
                                    <strong>Other Features: </strong>
                                    {existOtherAttrStr.substr(0, 20)}
                                    {existOtherAttrStr.length > 20 &&
                                        <Tooltip text={existOtherAttrStr}/>
                                    }
                                </div>
                            }
                        </div>
                    }

                    { ! existCampsiteFilter  && campsites.length > 0 &&
                        <div className="filter-line">
                            <strong>User Selected Campsites: </strong>
                            {campsitesStr.substring(0, 20)}
                            {campsitesStr.length > 20 &&
                                <Tooltip text={campsitesStr}/>
                            }
                        </div>
                    }

                    <div className="filter-line">
                        <strong>Campsites Matches the Filter: </strong>
                        {campsites.length ? <span>{campsites.length}</span>: <span>All</span>}
                    </div>

                </div>
            </div>
        )
    }

}

export default TrackingFilterBlock;