import React, { Component } from 'react';

class TrackingActionBtn extends Component {

    render() {

        return (
            <div>
                <button
                    className="btn btn-white"
                    onClick={this.props.onClick}
                > {this.props.title} <span className={this.props.iconClassName}></span>
                </button>
            </div>
        )
    }
}

export default TrackingActionBtn;

