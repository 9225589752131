import React from 'react'
class UserNotes extends React.Component {
    render() {
        return (
            <div>
                {/*
                <div id="messages" th:if="${SUCCESS_MESSAGE != null || ERROR_MESSAGE != null}">
                    <div className="container" style="padding: 20px 0 0;">
                        <div className="row">
                            <div className="col-xs-12">
                                <div th:if="${SUCCESS_MESSAGE != null}" className="alert alert-success" role="alert">
                                    <span th:text="${SUCCESS_MESSAGE}"></span>
                                </div>
                                <div th:if="${ERROR_MESSAGE != null}" className="alert alert-danger" role="alert">
                                    <span th:text="${ERROR_MESSAGE}"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
            </div>
        )
    }
}
export default UserNotes