import React, { Component } from 'react';
import {Link} from "react-router-dom";
import NavItem from "../NavItem";

import AuthService from "../services/auth.service"

export default class Navigation extends Component {

    state = {
        user: JSON.parse(localStorage.getItem('user'))
    };

    logOut() {
        AuthService.logout();
    }

    render() {
        const {user} = this.state;

        return (
            <nav className="navbar navbar-default global-box-shadow" id="nav">
                <div className="container">

                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <div className="logo">
                            <Link to="/account/tracking" className="navbar-brand">CAMPING WHIZZ</Link>
                        </div>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <NavItem to="/account/tracking">All trackings</NavItem>
                            <NavItem to="/account/tracking/add">Add new tracking</NavItem>
                        </ul>

                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to="/account/profile">
                                    <span className="icon-user"/>
                                    <span> {user && user.firstName}</span>
                                </Link>
                            </li>
                            <li>
                                <a href="/login" className="nav-link" onClick={this.logOut}>
                                    Sign Out
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}