import React, { Component } from 'react';
import {Formik, Form, Field} from "formik";

import AuthService from "../../services/auth.service";

import * as Yup from "yup";

export default class ProfilePassword extends Component {

    state = {
        errorMessage: null,
        successMessage: null,
        loading: false,
    };

    async componentDidMount() { };

    submitForm = async (values, { resetForm }) => {
        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            const response = await AuthService.updatePassword(values.currentPassword,values.password);
            this.setState({successMessage : response.data.message});
            resetForm();
        } catch(err) {
            this.setState({errorMessage : err.response.data.message});
        }

        this.setState({ loading: false });
    };

    render() {

        // validation
        const formValues = {
            currentPassword: "",
            password: "",
            confirmPassword: "",
        };

        const validationSchema = Yup.object().shape({
            currentPassword: Yup.string()
                .required("Current Password is required"),
            password: Yup.string()
                .required("New Password is required")
                .min(6, "Password must be at least 6 characters")
                .max(40, "Password must be at most 40 characters"),
            confirmPassword: Yup.string()
                .required("Password Confirm is required")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        });

        return (

            <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={this.submitForm}
            >
                {(formik) => {
                    return (
                        <Form noValidate id="password-form">

                            {this.state.errorMessage &&
                            <div>
                                <div className="alert alert-danger">{ this.state.errorMessage }</div>
                            </div>
                            }

                            {this.state.successMessage &&
                            <div>
                                <div className="alert alert-success">{ this.state.successMessage }</div>
                            </div>
                            }

                            <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                            +(formik.errors.currentPassword && formik.touched.currentPassword ? "wrong-email":"")}>
                                <Field type="password" name="currentPassword" placeholder="" className="form-control" />
                                <label htmlFor="currentPassword">
                                    {formik.errors.currentPassword && formik.touched.currentPassword
                                        ? formik.errors.currentPassword
                                        : "Current Password"
                                    }
                                </label>
                                <span className="icon-lock icon-form"></span>
                            </div>


                            <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                            +(formik.errors.password && formik.touched.password ? "wrong-email":"")}>
                                <Field type="password" name="password" placeholder="" className="form-control" />
                                <label htmlFor="password">
                                    {formik.errors.password && formik.touched.password
                                        ? formik.errors.password
                                        : "New Password"
                                    }
                                </label>
                                <span className="icon-lock icon-form"></span>
                            </div>

                            <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                            +(formik.errors.confirmPassword && formik.touched.confirmPassword ? "wrong-email":"")}>
                                <Field type="password" name="confirmPassword" placeholder="" className="form-control" />
                                <label htmlFor="confirmPassword">
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword
                                        ? formik.errors.confirmPassword
                                        : "Confirm New Password"
                                    }
                                </label>
                                <span className="icon-lock icon-form"></span>
                            </div>

                            <div className="button-center mt-20">
                                <button type="submit" disabled={this.state.loading} className="btn btn-green">
                                    <span className="inner-btn">Save</span>
                                </button>
                            </div>

                        </Form>
                    );
                }}
            </Formik>

        );
    }
}