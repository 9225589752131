import React from 'react';
import { Link, Route } from 'react-router-dom'

const NavItem = ({ to, ...rest }) => (
    <Route
        path={to}
        children={({ match }) => (
            <li className={match && match.isExact ? "active" : ""}>
                <Link to={to} {...rest} />
            </li>
        )}
    />
);

export default NavItem;