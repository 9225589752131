import React, { Component } from 'react'
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import SelectChkbx from '../../SelectChkbx';

import {Formik, Form} from "formik";
import * as Yup from "yup";

import FacilityService from "../../services/facilityService"

import Datetime from 'react-datetime';
import '../../css/plugins/react-datetime.css';
import CampsiteService from "../../services/campsite.service";
import TrackingService from "../../services/tracking.service"

export default class TrackingAdd extends Component {

    lengthOfStayOptions = [
        { value: '1', label: '1 day' },
        { value: '2', label: '2 days' },
        { value: '3', label: '3 days' },
        { value: '4', label: '4 days' },
        { value: '5', label: '5 days' },
        { value: '6', label: '6 days' },
        { value: '7', label: '7 days' }
    ];

    campsites = []; // all campsites of selected campground
    facilities = []; // list of facility objects match user search text
    campsiteTypeFilter = new Set(); // selected campsite types as a Set for fast search
    campsiteLoopFilter = new Set(); // selected campsite loops as a Set for fast search

    state = {
        loading: false,
        errorMessage: null,
        successMessage: null,

        facilityIDSelected: null,

        showCampsiteList: false,

        dateCheckInStart: new moment(),
        dateCheckInEnd: new moment(),
        lengthOfStaySelected: this.lengthOfStayOptions[0],
        campsitesSelected: [], // campsites selected in individual campsite dropdown
        campsiteTypesSelected: [], // selected campsite types
        campsiteLoopsSelected: [], // selected campsite loops

        campsiteOptions:[], // available campsite options
        campsiteTypeOptions: [], // available campsite type options
        campsiteLoopOptions: [], // available campsite loop options
        accessibilityNeeds: false,
        flexibleCheckIn: false,
        attributeFilter: new Map(), // selected attributes to filter by
    };

    handleLengthOfStayChange = (lengthOfStaySelected) => {
        this.setState({lengthOfStaySelected});
    };

    handleDateCheckInStartChange = (dateCheckInStart) => {
        this.setState({ dateCheckInStart: dateCheckInStart });

        if (this.state.dateCheckInEnd.isBefore(dateCheckInStart)) {
            this.setState({dateCheckInEnd: dateCheckInStart});
        }
    };

    handleDateCheckInEndChange = (dateCheckInEnd) => {
        this.setState({ dateCheckInEnd: dateCheckInEnd });
    };

    yesterday = moment().subtract(1, 'day');
    isCheckInStartValid = (current) => {
        return current.isAfter(this.yesterday);
    }

    isCheckInEndValid = (current) => {
        return current.isAfter(moment(this.state.dateCheckInStart).subtract(1, 'day'));
    }


    loadFacilities = async (inputValue) => {
        if (! inputValue.length) {
            return null;
        }
        const response = await FacilityService.search(inputValue);
        this.facilities = response.data;
        return this.facilities.map(v => {return {value:v.id, label: v.name}});
    };

    handleFacilityChange = async (option) => {
        const response = await CampsiteService.getByFacility(option.value);
        this.campsites = response.data;

        this.campsiteTypeFilter.clear();
        this.campsiteLoopFilter.clear();
        // clear other filters here

        const campsiteOptions = [];

        this.campsites.forEach(campsite => {
            this.campsiteTypeFilter.add(campsite.type);
            this.campsiteLoopFilter.add(campsite.loop);
            campsiteOptions.push({value: campsite.id, label: campsite.name});
        });
        const campsiteTypeOptions = [...this.campsiteTypeFilter].map(v=>{return {value: v, label: v}});
        const campsiteLoopOptions = [...this.campsiteLoopFilter].map(v=>{return {value: v, label: v}});

        // load all options and clear everything selected before
        this.setState({
            facilityIDSelected: option.value,
            campsiteOptions: campsiteOptions,
            campsiteTypeOptions: campsiteTypeOptions,
            campsiteLoopOptions: campsiteLoopOptions,
            campsitesSelected: campsiteOptions,
            campsiteTypesSelected: campsiteTypeOptions,
            campsiteLoopsSelected: campsiteLoopOptions,
            accessibilityNeeds: false,
            attributeFilter: new Map(),
            // uncheck other attributes here
        });
    };


    handleFlexibleCheckInChange = (event) => {
        this.setState({flexibleCheckIn: event.target.checked});
        if ( ! event.target.checked) {
            this.setState({dateCheckInEnd: this.state.dateCheckInStart});
        }
    };

    handleCampsiteTypeChange = (options) => {
        this.setState({campsiteTypesSelected : options});
        this.campsiteTypeFilter.clear();
        options.forEach(option => {this.campsiteTypeFilter.add(option.value)});
        this.filterCampsites();
    };

    handleCampsiteLoopChange = (options) => {
        this.setState({campsiteLoopsSelected : options});
        this.campsiteLoopFilter.clear();
        options.forEach(option => {this.campsiteLoopFilter.add(option.value)});
        this.filterCampsites();
    };

    handleAccesibilityNeedsChange = (event) => {
        this.setState({
            accessibilityNeeds: event.target.checked
        }, () =>{
            this.filterCampsites();
        });
    };

    handleAttributeChange = (event) => {
        const target = event.target;

        this.setState(oldState => {
            let attributeFilter = oldState.attributeFilter;

            if ((target.type === 'checkbox' && target.checked)
                || (target.type === 'text' && target.value)
                || (target.type === 'number' && target.value)
            ) {
                attributeFilter.set(target.name, target.value);
            } else {
                attributeFilter.delete(target.name);
            }
            return  {attributeFilter: attributeFilter};
        }, () =>{
            this.filterCampsites();
        });
    };

    handleCampsitesChange = (options) => {
        this.setState({campsitesSelected : options});
    };

    filterCampsites = () => {
        this.setState(oldState => {
            const campsitesFiltered = this.campsites.filter(campsite => {

                if ( ! this.campsiteTypeFilter.has(campsite.type)) {
                    return false;
                }
                if ( ! this.campsiteLoopFilter.has(campsite.loop)) {
                    return false;
                }
                if (this.state.accessibilityNeeds && campsite.accessible !== 'Yes') {
                    return false;
                }


                if (oldState.attributeFilter.size > 0) { // filter by attributes
                    let campsiteAttributes = new Map(campsite.attributes.map((i)=>[i.name, i.value]));
                    let BreakException = {};

                    try {
                        oldState.attributeFilter.forEach(function(attrValue, attrKey){
                            switch(attrKey) {
                                case "attrWaterfront":
                                    if ( ! campsiteAttributes.has('Proximity to Water')){
                                        throw BreakException;
                                    }
                                    break;
                                case "attrPullThroughDriveway":
                                    if (campsiteAttributes.get('Driveway Entry') !== attrValue){
                                        throw BreakException;
                                    }
                                    break;
                                case "attrSewerHookup":
                                    if (campsiteAttributes.get('Sewer Hookup') !== attrValue){
                                        throw BreakException;
                                    }
                                    break;
                                case "attrWaterHookup":
                                    if (campsiteAttributes.get('Water Hookup') !== attrValue){
                                        throw BreakException;
                                    }
                                    break;
                                case "attrPetsAllowed":
                                    if (campsiteAttributes.get('Pets Allowed') !== attrValue){
                                        throw BreakException;
                                    }
                                    break;
                                case "attrOccupants":
                                    if (campsiteAttributes.get('Max Num of People') < parseInt(attrValue)){
                                        throw BreakException;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        });
                    } catch (e) {
                        if (e !== BreakException) throw e;
                        return false;
                    }
                }

                return true;
            });

            return {
                campsitesSelected: campsitesFiltered.map(v=>{return {value: v.id, label: v.name}})
            }
        })
    };

    handleFilterTypeChange = (changeEvent) => {
        this.setState({showCampsiteList: changeEvent.target.value === "campsites"});
        if (this.state.showCampsiteList) {
            this.filterCampsites();
        }
    };

    submitForm = async (values) => {

        let campsiteFilter = {};
        if ( ! this.state.showCampsiteList) {
            if (this.state.campsiteLoopsSelected && this.state.campsiteLoopsSelected.length !== this.state.campsiteLoopOptions.length) {
                campsiteFilter["loops"] = this.state.campsiteLoopsSelected.map(v=>v.value);
            }
            if (this.state.campsiteTypesSelected && this.state.campsiteTypesSelected.length !== this.state.campsiteTypeOptions.length) {
                campsiteFilter["types"] = this.state.campsiteTypesSelected.map(v=>v.value);
            }
            if (this.state.attributeFilter.size) {
                campsiteFilter["attributes"] = Object.fromEntries(this.state.attributeFilter);
            }
            if (this.state.accessibilityNeeds) {
                campsiteFilter["accessibilityNeeds"] = true;
            }
        }



        let campsites = (this.state.campsitesSelected.length === this.state.campsiteOptions.length)
            ? [] : this.state.campsitesSelected.map(v=>{return {id: v.value, name:v.label}});

        let data = {
            facility: {id: this.state.facilityIDSelected},
            dateCheckInStart: this.state.dateCheckInStart.format("YYYY-MM-DD"),
            dateCheckInEnd: this.state.dateCheckInEnd.format("YYYY-MM-DD"),
            lengthOfStay: this.state.lengthOfStaySelected.value,
            campsites: JSON.stringify(campsites),
            campsiteFilter: JSON.stringify(campsiteFilter)
        }

        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            await TrackingService.create(data);
            this.setState({successMessage : "Success! Tracking created!"});

            let history = this.props.history;
            setTimeout(() => history.push("/account/tracking"), 1000);
        } catch(err) {
            this.setState({errorMessage : err.response.data.message});
        }
        this.setState({ loading: false });

    };

    render() {
        const { campsiteOptions, campsitesSelected, campsiteTypeOptions, campsiteTypesSelected,
            campsiteLoopOptions, campsiteLoopsSelected, showCampsiteList } = this.state;

        // validation
        const formValues = {
            facilityID: ""
        };

        const validationSchema = Yup.object().shape({
            facilityID: Yup.string()
                .required("Campground is required"),
        });

        return (
            <div id="tracking">
                <div className="container">
                    <div className="row">

                        <Formik
                            initialValues={formValues}
                            validationSchema={validationSchema}
                            onSubmit={this.submitForm}
                        >
                            {(formik) => {
                                return (
                                    <Form noValidate>
                                        <div className="col-xs-12">
                                            <div className="heading-wrapper">
                                                <h2>New camping tracking</h2>
                                            </div>
                                            <div className="row">

                                                <div className="form-wrap col-xs-12 col-sm-6">
                                                    <div className={""+(formik.errors.facilityID && formik.touched.facilityID ? "has-error":"")}>
                                                        <label htmlFor="facility" data-default="Campground name:">
                                                            Campground name:
                                                        </label>
                                                    </div>

                                                    <AsyncSelect
                                                        placeholder="Start typing..."
                                                        onChange={e => {
                                                            formik.setFieldValue('facilityID', e.value);
                                                            this.handleFacilityChange(e)
                                                        }}
                                                        onBlur={() => formik.setFieldTouched('facilityID', true)}
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={this.loadFacilities}
                                                    />
                                                    {/*ErrorMessage formik component can be used instead*/}
                                                    {/*<ErrorMessage name="facilityID" component="span" className="error" />*/}
                                                    {/*{formik.errors.facilityID && formik.touched.facilityID && (
                                                        <span className="error">{formik.errors.facilityID}</span>
                                                    )}*/}
                                                </div>

                                                <div className="form-wrap col-xs-12 col-sm-6">
                                                    <label htmlFor="lengthOfStay">Length Of Stay:</label>
                                                    <Select
                                                        inputId="lengthOfStay"
                                                        value={this.state.lengthOfStaySelected}
                                                        onChange={this.handleLengthOfStayChange}
                                                        options={this.lengthOfStayOptions}
                                                    />
                                                </div>

                                                <div className="form-wrap col-xs-12">
                                                    <div className="flx flx-yc check-in-wrap">
                                                        <div className="check-in-label">
                                                            <label htmlFor="dateCheckInStart">Check-In Date:</label>
                                                        </div>

                                                        <div className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id="flexibleCheckIn"
                                                                name="flexibleCheckIn"
                                                                checked={this.state.flexibleCheckIn}
                                                                onChange={this.handleFlexibleCheckInChange}
                                                                className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                            />
                                                            <label htmlFor="flexibleCheckIn">Flexible Check-In (any date in a range)</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-wrap check-in-start col-xs-12 col-sm-4">
                                                    <div>
                                                        <div className="form-group-sm">
                                                            <Datetime
                                                                inputProps={{
                                                                    placeholder: "Select Date",
                                                                    onKeyDown: (e) => e.preventDefault(),
                                                                }}
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                value={this.state.dateCheckInStart}
                                                                onChange={this.handleDateCheckInStartChange}
                                                                isValidDate={this.isCheckInStartValid}
                                                                ref={ref => {this.dateCheckInStartRef = ref}}
                                                            />
                                                            <span className="icon-calendar icon-form" onClick={() => this.dateCheckInStartRef._openCalendar()}/>
                                                        </div>
                                                    </div>

                                                </div>

                                                { this.state.flexibleCheckIn &&
                                                    <div className="form-wrap check-in-end col-xs-12 col-sm-4">
                                                        <div>
                                                            <div className="form-group-sm">
                                                                <Datetime
                                                                    inputProps={{
                                                                        placeholder: "Select Date",
                                                                        onKeyDown: (e) => e.preventDefault(),
                                                                    }}
                                                                    timeFormat={false}
                                                                    closeOnSelect={true}
                                                                    value={this.state.dateCheckInEnd}
                                                                    onChange={this.handleDateCheckInEndChange}
                                                                    isValidDate={this.isCheckInEndValid}
                                                                    ref={ref => {this.dateCheckInEndRef = ref}}
                                                                />
                                                                <span className="icon-calendar icon-form" onClick={() => this.dateCheckInEndRef._openCalendar()}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            <div className="row">

                                                <div className="col-xs-12"><br/><br/><hr/></div>

                                                <div className="col-xs-12">
                                                    <h5>Additional Filters (not required)</h5>
                                                    <p>Please note that changing default filter settings decreases your chances to
                                                        reserve a campsite. </p>
                                                </div>

                                                <div className="filter-wrap tracking-wrap col-xs-12">

                                                    <div className="filter-radio flx flx-jc-xc radio-wrap">
                                                        <div className="radio">
                                                            <input type="radio"
                                                                   id="filterByFeature"
                                                                   value="features"
                                                                   onChange={this.handleFilterTypeChange}
                                                                   checked={!showCampsiteList}/>
                                                            <label htmlFor="filterByFeature">By Features</label>
                                                        </div>
                                                        <div className="radio">
                                                            <input type="radio"
                                                                   id="filterByCampsites"
                                                                   value="campsites"
                                                                   onChange={this.handleFilterTypeChange}
                                                                   checked={showCampsiteList}/>
                                                            <label htmlFor="filterByCampsites">By Campsites</label>
                                                        </div>
                                                    </div>
                                                    { ! showCampsiteList &&
                                                    <div>
                                                        <div
                                                            className="tracking-filter-box campsites-filter campsite-dropdown row featuresFilterDiv">
                                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                                <label htmlFor="campsiteType">Campsite Type:</label>

                                                                <SelectChkbx
                                                                    placeholder="Please Select"
                                                                    inputId="campsiteType"
                                                                    value={campsiteTypesSelected}
                                                                    options={campsiteTypeOptions}
                                                                    onChange={this.handleCampsiteTypeChange}
                                                                />
                                                            </div>

                                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                                <label htmlFor="campsiteLoop">Campsite Loop:</label>
                                                                <SelectChkbx
                                                                    placeholder="Please Select"
                                                                    inputId="campsiteLoop"
                                                                    value={campsiteLoopsSelected}
                                                                    options={campsiteLoopOptions}
                                                                    onChange={this.handleCampsiteLoopChange}
                                                                />
                                                            </div>

                                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                                <div className="form-wrap">
                                                                    <div>
                                                                        <label htmlFor="attrOccupants">Number of Occupants:</label>
                                                                        <div className="form-group-sm  floating-label-form-group">
                                                                            <input
                                                                                type="number"
                                                                                id="attrOccupants"
                                                                                name="attrOccupants"
                                                                                value={this.state.attributeFilter.get("attrOccupants") || ""}
                                                                                onChange={this.handleAttributeChange}
                                                                                min="1"
                                                                                max="99"
                                                                                maxLength="2"
                                                                                className="form-control campsiteFilterAttr"
                                                                                placeholder="- Any -"

                                                                            />
                                                                            <span className="icon-user icon-form"/>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="tracking-filter-box campsites-filter row featuresFilterDiv"
                                                             data-tracking="features">
                                                            <div className="col-xs-12"><h6>Other Features:</h6></div>
                                                            <div className="col-xs-12 col-sm-6 col-md-4 form-wrap">
                                                                <div className="flx flx-wrap">
                                                                    <div className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="accessibilityNeeds"
                                                                            name="accessibilityNeeds"
                                                                            checked={this.state.accessibilityNeeds}
                                                                            onChange={this.handleAccesibilityNeedsChange}
                                                                            className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                                        />
                                                                        <label htmlFor="accessibilityNeeds">Accessibility needs</label>
                                                                    </div>
                                                                    <div className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="attrPetsAllowed"
                                                                            name="attrPetsAllowed"
                                                                            checked={this.state.attributeFilter.has("attrPetsAllowed")}
                                                                            onChange={this.handleAttributeChange}
                                                                            className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                                            value="Domestic"
                                                                        />
                                                                        <label htmlFor="attrPetsAllowed">Pets allowed</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-6 col-md-4 form-wrap">
                                                                <div className="flx flx-wrap">
                                                                    <div className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="attrWaterHookup"
                                                                            name="attrWaterHookup"
                                                                            checked={this.state.attributeFilter.has("attrWaterHookup")}
                                                                            onChange={this.handleAttributeChange}
                                                                            className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                                            value="Y"
                                                                        />
                                                                        <label htmlFor="attrWaterHookup">Water hookup</label>
                                                                    </div>
                                                                    <div className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="attrSewerHookup"
                                                                            name="attrSewerHookup"
                                                                            checked={this.state.attributeFilter.has("attrSewerHookup")}
                                                                            onChange={this.handleAttributeChange}
                                                                            className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                                            value="Y"
                                                                        />
                                                                        <label htmlFor="attrSewerHookup">Sewer hookup</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-6 col-md-4 form-wrap">
                                                                <div className="flx flx-wrap">
                                                                    <div className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="attrPullThroughDriveway"
                                                                            name="attrPullThroughDriveway"
                                                                            checked={this.state.attributeFilter.has("attrPullThroughDriveway")}
                                                                            onChange={this.handleAttributeChange}
                                                                            className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                                            value="Pull-Through"
                                                                        />
                                                                        <label htmlFor="attrPullThroughDriveway">Pull-through driveway</label>
                                                                    </div>
                                                                    <div className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="attrWaterfront"
                                                                            name="attrWaterfront"
                                                                            checked={this.state.attributeFilter.has("attrWaterfront")}
                                                                            onChange={this.handleAttributeChange}
                                                                            className="campsiteFilterAttr campsiteOther checkbox-tracking"
                                                                            value="Y"
                                                                        />
                                                                        <label htmlFor="attrWaterfront">Waterfront</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12"><h5>Potential number of campsites matches the
                                                                filter: <span id="campsiteFilterCount">{this.state.campsitesSelected.length}</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    {showCampsiteList &&
                                                    <div
                                                        className="tracking-filter-box campsites-filter campsite-dropdown row campsitesFilterDiv">
                                                        <div className="col-xs-12 col-sm-6 col-md-4">
                                                            <label htmlFor="campsites">Campsites:</label>
                                                            <SelectChkbx
                                                                inputId="campsites"
                                                                value={campsitesSelected}
                                                                options={campsiteOptions}
                                                                onChange={this.handleCampsitesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    }

                                                </div>
                                            </div>

                                            <div className="col-xs-12">
                                                <div className="text-center">

                                                    {this.state.errorMessage &&
                                                    <div>
                                                        <div className="alert alert-danger">{ this.state.errorMessage }</div>
                                                    </div>
                                                    }

                                                    {this.state.successMessage &&
                                                    <div>
                                                        <div className="alert alert-success">{ this.state.successMessage }</div>
                                                    </div>
                                                    }

                                                    <button type="submit" disabled={this.state.loading} className="btn btn-green">
                                                        <span className="inner-btn">Submit</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </div>
                </div>

            </div>
        );
    }
}
