import React, { Component } from 'react';
import {Formik, Form, Field} from "formik";

import AuthService from "../services/auth.service";

import * as Yup from "yup";
import SimpleNavigation from "../SimpleNavigation";

export default class Confirm extends Component {

    state = {
        errorMessage: null,
        loading: false
    };

    async componentDidMount() {};

    submitForm = async (values) => {
        this.setState({errorMessage:null});

        this.setState({ loading: true });

        try {
            await AuthService.confirm(this.props.match.params.token, values.password);
            this.props.history.push("/account/tracking");
        } catch(err) {
            this.setState({errorMessage : err.response.data.message});
        }

        this.setState({ loading: false });
    };

    render() {

        // validation
        const formValues = {
            password: "",
            passwordConfirm: "",
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .required("New Password is required")
                .min(6, "Password must be at least 6 characters")
                .max(40, "Password must be at most 40 characters"),

            passwordConfirm: Yup.string()
                .required("Confirm Password is required")
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        });

        return (
            <div>
                <SimpleNavigation/>

                <div id="login">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 center-col">
                                <div className="heading-wrapper">
                                    <h1 className="button-center">Set Your New Password</h1>
                                </div>

                                <Formik
                                    initialValues={formValues}
                                    validationSchema={validationSchema}
                                    onSubmit={this.submitForm}
                                >
                                    {(formik) => {
                                        return (
                                            <Form noValidate>

                                                <div className="notification-box">Please enter your new password to access your account.</div>

                                                {this.state.errorMessage &&
                                                <div>
                                                    <div className="alert alert-danger">{ this.state.errorMessage }</div>
                                                </div>
                                                }

                                                {this.state.successMessage &&
                                                <div>
                                                    <div className="alert alert-success">{ this.state.successMessage }</div>
                                                </div>
                                                }

                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.password && formik.touched.password ? "wrong-email":"")}>
                                                    <Field type="password" name="password" placeholder="" className="form-control" />
                                                    <label htmlFor="password">
                                                        {formik.errors.password && formik.touched.password
                                                            ? formik.errors.password
                                                            : "New Password"
                                                        }
                                                    </label>
                                                    <span className="icon-lock icon-form"></span>
                                                </div>


                                                <div className={"form-group-sm  floating-label-form-group  floating-label-form-group-with-value "
                                                +(formik.errors.passwordConfirm && formik.touched.passwordConfirm ? "wrong-email":"")}>
                                                    <Field type="password" name="passwordConfirm" placeholder="" className="form-control" />
                                                    <label htmlFor="passwordConfirm">
                                                        {formik.errors.passwordConfirm && formik.touched.passwordConfirm
                                                            ? formik.errors.passwordConfirm
                                                            : "Confirm Password"
                                                        }
                                                    </label>
                                                    <span className="icon-lock icon-form"></span>
                                                </div>

                                                <div className="button-center">
                                                    <button type="submit" disabled={this.state.loading} className="btn btn-green"><span className="inner-btn">Save Password</span>
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                                <div className="button-center">
                                    <a className="forgot-pass" href="/restorePassword">Forgot your password?</a>
                                </div>
                                <div className="button-center">
                                    <a className="forgot-pass" href="/register">New user? Sign up to create your
                                        account.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


