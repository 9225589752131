import React from 'react'
const App = () =>
    <div>
        <section id="hero">
            <div class="hero-bg flx flx-yc">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-11 col-md-9 col-lg-8  center-col heading-content-bg">
                            <div class="heading-wrapper">
                                <h1>Camping Whizz</h1>
                                <h4>Have you ever had to cancel your trip to Yosemite, Death Valley or other beautiful
                                    places all over the country just because
                                    all campground around were reserved?<br/>
                                    If yes, you know how painful it is and we think that we can possible help a lot of
                                    people who are in similar situation.<br/>
                                    There's a pretty good chance that someone at that campground will make a
                                    cancellation.
                                    Camping Whizz will monitor and send you a notification immediately once your
                                    favorite campground have any available campsite.<br/>
                                    That's awesome, right?</h4>
                            </div>
                            <div class="btn-wrap">
                                <a class="btn btn-green" href="/account/tracking"><span class="inner-btn">Start Monitoring</span></a>
                            </div>
                        </div>
                    </div>

                    <div class="links-wrap">
                        <a id="sign-in" href="/account/tracking"><span class="icon-user"></span> Account</a>
                        <a id="sign-up" href="/register"><span class="icon-register"></span> Sign up</a>
                    </div>

                </div>
            </div>
        </section>

        <section id="how">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-5 col-lg-6">
                        <img src="/images/camping-vect.png" alt="Camping Whizz"/>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-7 col-lg-6">

                        <div class="heading-wrapper">
                            <h2>How does Camping Whizz work?</h2>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-select"></span>
                            <span>Log in to your account and type a campground name where you want to camp.</span>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-filter"></span>
                            <span>Select check-in date (or check in date range if you are flexible), length of stay, and filter your favorite campsites if you have other preferences.</span><br/>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-filter"></span>
                            <span>You can filter by campsite type, campsite loop or even select just specific sites by the lake with that amazing views of mountains.</span>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-monitoring"></span>
                            <span>Let Camping Whizz to check every hour if selected campground has any available campsite which satisfies your requirements.</span>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-notification"></span>
                            <span>Get an email notification when any of those is available.</span>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-camp"></span>
                            <span>Log in to your Camping Whizz account as soon as possible, click on "Book" link and complete a reservation form on recreation.gov website.</span>
                        </div>

                        <div class="flx flx-yc how-wrap">
                            <span class="icon-camp"></span>
                            <span>Pack your stuff for a trip and have fun!</span>
                        </div>

                        <div class="btn-wrap">
                            <a class="btn btn-green" href="/account/tracking"><span class="inner-btn">Start Monitoring</span></a>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
export default App